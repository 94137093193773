import { useTranslation } from 'next-i18next'
import Image from '~/components/shared/image/Image'
import CommonHead from '~/components/shared/CommonHead/CommonHead'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import style from './404.module.scss'
import { Button, Result } from 'antd'
const Custom404 = () => {
  const { t } = useTranslation()

  return (
    <>
      <CommonHead
        title={'Không tìm thấy trang - 404'}
        description={t('common:meta.home.desc')}
        keywords={t('common:meta.keywords')}
      />
      <Result
        status="404"
        title="404"
        subTitle="Rất tiếc, trang bạn tìm kiếm không tồn tại."
        extra={
          <Button
            style={{ backgroundColor: '#FDB913', color: 'black' }}
            type="primary"
            onClick={() => {
              window.location.href = "https://www.vib.com.vn/vn/home";
            }}
          >
            Về trang chủ
          </Button>
        }
      />
    </>
  )
}

export const getStaticProps = async ({ locale = 'en' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Custom404
